src/views/pages/perfiles/usuarios/PhotoCard.vue
<template>
  <div>
    <!-- Versión de escritorio -->
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <common-card>
        <v-card-text>
          <v-row style="padding-bottom: 15px; padding-top: 15px;">
            <v-col cols="8">
              <v-row>
                <v-col class="full-center-div" cols="3">
                  <v-avatar
                    color="primary"
                    style="height: 100px; min-width: 100px; cursor: pointer;"
                  >
                    <span class="white--text text-h5">
                      {{
                        (
                          name.split(" ")[0][0] +
                          (name.split(" ")[1] ? name.split(" ")[1][0] : "")
                        ).toUpperCase()
                      }}
                    </span>
                  </v-avatar>
                </v-col>
                <v-col cols="9" style="padding-left: 0;">
                  <div class="text-h5 font-weight-bold" style="line-height: 6rem;">
                    {{
                      personalData.nombre +
                        " " +
                        personalData.apellidoPaterno +
                        " " +
                        personalData.apellidoMaterno
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-divider
              vertical
              style="
                border-color: #00a7e4;
                border-width: 2px;
                height: 103px;
                margin-top: 9px;
                margin-right: 8px;"
            ></v-divider>
            <v-col cols="3">
              <div>
                <div class="text-body-1 mb-1">
                  <span class="font-weight-bold">ID:</span> {{ personalData.id }}
                </div>
              </div>
              <div class="mt-3">
                <div class="text-body-1 mb-1">
                  <span class="font-weight-bold">Ubicación:</span>
                  {{ getStateName(personalData.nacimientoEstadoId) }}
                </div>
              </div>
              <div class="mt-3">
                <div class="text-body-1 mb-1">
                  <span class="font-weight-bold">Estatus:</span>
                  {{ getStatus(personalData.status) }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </common-card>
    </template>

    <!-- Versión móvil -->
    <template v-else>
      <common-card>
        <v-card-text>
          <v-row
            class="photo-card-mobile-layout"
            style="padding-bottom: 15px; padding-top: 15px;"
            align="center"
          >
            <!-- Avatar centrado -->
            <v-col cols="12" class="full-center-div text-center">
              <v-avatar
                color="primary"
                style="height: 100px; min-width: 100px; cursor: pointer;"
              >
                <span class="white--text text-h5">
                  {{
                    (
                      name.split(" ")[0][0] +
                      (name.split(" ")[1] ? name.split(" ")[1][0] : "")
                    ).toUpperCase()
                  }}
                </span>
              </v-avatar>
            </v-col>

            <!-- Nombre debajo de la foto -->
            <v-col cols="12" class="text-center">
              <div class="text-h5 font-weight-bold" style="line-height: 2rem;">
                {{
                  personalData.nombre +
                    " " +
                    personalData.apellidoPaterno +
                    " " +
                    personalData.apellidoMaterno
                }}
              </div>
            </v-col>

            <!-- Información adicional -->
            <v-col cols="12" class="text-center">
              <div>
                <div class="text-body-1 mb-1">
                  <span class="font-weight-bold">ID:</span> {{ personalData.id }}
                </div>
              </div>
              <div class="mt-3">
                <div class="text-body-1 mb-1">
                  <span class="font-weight-bold">Ubicación:</span>
                  {{ getStateName(personalData.nacimientoEstadoId) }}
                </div>
              </div>
              <div class="mt-3">
                <div class="text-body-1 mb-1">
                  <span class="font-weight-bold">Estatus:</span>
                  {{ getStatus(personalData.status) }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </common-card>
    </template>
  </div>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";

export default {
  components: {
    CommonCard,
  },
  props: {
    personalData: Object,
    stateList: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      name: localStorage.agenteName,
    };
  },
  methods: {
    getStateName(id) {
      if (this.stateList.length > 0) {
        const state = this.stateList.find(e => e.cEstado == id);
        return state ? state.dEstado : "No disponible";
      }
      else return "No disponible";
    },
    getStatus(status) {
      switch (status) {
        case -1:
          return "Baja";
        case 0:
          return "Nuevo";
        case 1:
          return "Activo";
        case 2:
          return "Inactivo";
        default:
          return "Nuevo";
      }
    },
  },
};
</script>

<style scoped>
.full-center-div {
  align-self: center;
  text-align: center;
}

@media (max-width: 959px) {
  /* Estilo específico para móvil */
  .photo-card-layout {
    flex-direction: column !important;
  }
  .photo-card-layout .v-col {
    margin-bottom: 10px;
  }
  .photo-card-layout .v-avatar {
    margin: 0 auto;
  }
}
</style>



