<template>
  <div>
    <CommonExpansionPanel @change="togglePanel">
      <template v-slot:title-panel>
        <div class="d-flex justify-space-between align-center">
          <span>Ejecutivo comercial asignado</span>
          <!-- No se muestran botones ya que es solo maquetado -->
        </div>
      </template>

      <v-expansion-panel-content>
        <!-- Versión escritorio -->
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-row>
            <v-col cols="6">
              <p>
                {{ managerData.nombreCompleto }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <p>
                <strong style="color:#00a7e4">
                  {{ managerData.celular ? "Celular:" : "Teléfono:" }}
                </strong>
                <br />
                {{
                  managerData.celular || managerData.telefono || "No disponible"
                }}
              </p>
            </v-col>
            <v-col cols="6">
              <p>
                <strong style="color:#00a7e4">Email:</strong><br />
                {{ managerData.correo || "No disponible" }}
              </p>
            </v-col>
          </v-row>
        </template>

        <!-- Versión móvil -->
        <template v-else>
          <div class="mobile-panel-content">
            <p>
              <strong>Teléfono:</strong>
              {{
                managerData.telefono || managerData.celular || "No disponible"
              }}
            </p>
            <p>
              <strong>Email:</strong>
              {{ managerData.correo || "No disponible" }}
            </p>
          </div>
        </template>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import { mainAxios } from "~/mainAxios.js";

export default {
  name: "EjecutivoComercialAsignado",
  components: {
    CommonExpansionPanel,
  },
  props: {
    contactData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPanelExpanded: false,
      isMobile: window.innerWidth < 768,
      localAgentData: {},
      managerData: {},

      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
    };
  },
  methods: {
    togglePanel(expanded) {
      this.isPanelExpanded = expanded;
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth < 768;
    },

    async getManagerData() {
      await mainAxios
        .get(
          `v1/agente/asignacion/info/${this.localAgentData.idAgente}`,
          this.config
        )
        .then((response) => {
          this.managerData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    contactData: {
      handler(newData) {
        if (newData) {
          this.localAgentData = { ...newData }; // Copy the object to avoid reactivity issues
        }
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    window.addEventListener("resize", this.checkScreenSize);
    this.getManagerData();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>

<style scoped>
/* Estilos para alinear y dar formato al contenido en versión móvil */
.mobile-panel-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

/* Clases de diseño para alinear contenido */
.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}
</style>
