<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <photo-card
              :personalData="personalData"
              :stateList="stateList"
              :transforToAgent="true"
            ></photo-card>
          </v-col>
          <v-col cols="12">
            <cards-info-panel
              :infoCards="infoCards"
              :routesConfigNoteCard="routesConfigNoteCard"
              :personalData="personalData"
              :emailRoutesConfig="emailRoutesConfig"
              :canEdit="canEdit"
              @getData="getData"
              @toggleDocumentsPanel="toggleDocumentsPanel"
            ></cards-info-panel>
          </v-col>

          <v-col cols="12">
            <personal-data-panel
              :personalData="personalData"
              :stateList="stateList"
              :routesConfig="routesConfig"
              :canEdit="canEdit"
              @getData="getData"
            ></personal-data-panel>
          </v-col>
          <v-col cols="12">
            <contact-data-panel
              :contactData="contactData"
              :correosDataFormat="correosDataFormat"
              :routesContactData="routesContactData"
              :canEdit="canEdit"
              @getData="getData"
            ></contact-data-panel>
          </v-col>
          <v-col cols="12">
            <security-data-panel
              :personalData="personalData"
              :routesConfigPassword="routesConfigPassword"
              :canEdit="canEdit"
              @getData="getData"
            ></security-data-panel>
          </v-col>
          <!-- Muestra panel de documentos solo si es admin -->
          <v-col cols="12" v-if="true">
            <AssignedDataPanel
              :isOpenDocumentsPanel="isOpenDocumentsPanel"
            ></AssignedDataPanel>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PhotoCard from "./PhotoCard.vue";
import PersonalDataPanel from "../components/personalData/PersonalDataPanel.vue";
import ContactDataPanel from "../components/contactData/ContactDataPanel.vue";
import SecurityDataPanel from "../components/securityData/SecurityDataPanel.vue";
import CardsInfoPanel from "../components/cardsInfo/CardsInfoPanel.vue";
import AssignedDataPanel from "../components/ProfileAssigned/AssignedDataPanel.vue";
export default {
  name: "UserProfile",
  components: {
    PhotoCard,
    PersonalDataPanel,
    ContactDataPanel,
    SecurityDataPanel,
    CardsInfoPanel,
    AssignedDataPanel,
  },
  data() {
    return {
      // Datos mock para el perfil de usuario
      personalData: {
        nombre: "Juan",
        apellidoPaterno: "Pérez",
        apellidoMaterno: "Gómez",
        sexo: "Masculino",
        rfc: "JUAP850101XXX",
        fechaNacimiento: "1985-01-01",
        nacimientoEstadoId: "1",
        correo: "juan.perez@example.com",
        celular: "555-0001",
        telefono: "555-0002",
        status: "Activo",
      },
      stateList: [
        { id: 1, nombre: "Estado 1" },
        { id: 2, nombre: "Estado 2" },
      ],
      routesConfig: {
        completeData: false,
        route: "/v1/prospecto/perfil",
        action: "PUT",
      },
      routesConfigPassword: {
        route: "/v1/prospecto/perfil/password",
        action: "PUT",
      },
      infoCards: {
        notas: [],
        docs: [],
        emails: [],
      },
      contactData: {
        celular: "555-0001",
        telefono: "555-0002",
      },
      routesConfigNoteCard: {
        route: "/v1/prospecto/documento/upload/mock",
        action: "POST",
      },
      correosDataFormat: {
        correos: [],
        action: "POST",
      },
      routesContactData: {
        routeCommond: "/v1/prospecto/perfil",
        routeCommondAction: "PUT",
        deleteEmails: "/v1/correos-contacto/prospecto/delete",
        changeEmails: "/v1/correo-contacto/prospecto/", // query param idCorreo para modificar correo
        insertEmails: "/v1/correo-contacto/prospecto/insert",
      },
      emailRoutesConfig: {
        sendEmailRoute: "/v1/portal/prospectoagente/correo/enviar",
        action: "POST",
        optionCorreosList: [],
        destinatario: "juan.perez@example.com",
      },
      canEdit: true,
      isOpenDocumentsPanel: false,
      // isAdmin: true, // Modifica este valor según convenga para el maquetado
    };
  },
  methods: {
    toggleDocumentsPanel() {
      this.isOpenDocumentsPanel = !this.isOpenDocumentsPanel;
    },
    getData() {
      // Stub para refrescar datos sin funcionalidad de backend
      console.log("getData llamado");
    },
  },
};
</script>
